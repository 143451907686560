import React from 'react';
import ReactDOM from 'react-dom/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import './index.scss';

const stripePromise = loadStripe(
  'pk_live_51OWkFoFoeu8eIgozTS027YoJmr0rGjkdht5LjqLQ0cSLik1LwXy80KRSb2zdtqDJkcqnC6g60T3gtZLLIHzyEgLC00yedyoz4D'
);
// const stripePromise = loadStripe(
//   'pk_test_51OWkFoFoeu8eIgozuorT0J4IBJLOsj8Yyx2lEPHLrzYrCuyoopDV7rCMtxk5pPEMPL61IbdQubSHA7ARFjSYdbmk00Efz7AKlg'
// );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
